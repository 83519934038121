/* Inserimos primeiro o que é necessário ao Tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* CSS padrão */
body {
  margin: 0;
  padding: 0;
  font-family: Helvetica, "Helvetica Neue", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Helvetica, "Helvetica Neue", "JetBrains Mono", source-code-pro,
    Menlo, Monaco, Consolas, "Courier New", monospace;
}
